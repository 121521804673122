import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import { Image } from 'semantic-ui-react'
// import 'semantic-ui-css/semantic.min.css'
import './App.scss';

const Home = () => (
  <div>
    <Image className='header__headshot' src='img/headshots/helen_172-highres.jpg' />
    <Image className='header__headshot' src='img/headshots/helen_241-highres.jpg' />
    <Image className='header__headshot' src='img/headshots/helen_339-highres.jpg' />
    <Image className='header__headshot' src='img/headshots/helen_5-highres.jpg' />
  </div>
)

const About = () => (
  <div className='about section'>
    <h2 className='section-header'>About</h2>
    <div className='container'>
      <div>
        <Image style={{ maxWidth: '300px' }} src='img/headshots/helen_294-highres.jpg' />
      </div>

      <div className='about__bio content'>
        Helen Hood is an actor based in New York City.
        <br/><br/>
        Originally from Rhode Island, she grew up dancing and playing piano but got the acting bug while choreographing her high school's musicals.
        <br/><br/>
        She is a graduate of The Studio/New York's Nine-Month Acting Conservatory. She was recently featured in <em><a href="https://www.imdb.com/title/tt8552748/?ref_=nv_sr_srsg_1" target="_blank">NY/LA</a></em> and can be seen in the upcoming new play <em>Omaha</em> premiering September 2022.
        <br/><br/>
        She lives in Brooklyn with her partner and their fluffy and deranged cat.
      </div>
    </div>
  </div>
)

const News = () => (
  <div className='news'>
    <h2 className='section-header'>News</h2>
    <div className='news__items'>
      <div className='news__item'>
        <div className='news__item__date'>
          2022.06.01
        </div>
        <div className='news__item__content'>
          Fun news! You can find me on stage this fall in <a href='https://www.instagram.com/omaha_the_play/' target='_blank'>Omaha</a>, a new play premiering in NYC in September. Tix available <a style={{ textDecoration: 'underline' }} href='https://ci.ovationtix.com/277' target='_blank'>here</a>.
        </div>
      </div>
      <div className='news__item'>
        <div className='news__item__date'>
          2022.01.31
        </div>
        <div className='news__item__content'>
          <p>Idk what to say about finally being on stage again after 2 years, and with these incredible people and batshit wonderful play. My heart is full. Here's to Troglobites going to Edinburgh???</p>
          <Image className='header__headshot' src='img/troglobites.jpg' />
        </div>
      </div>
      <div className='news__item'>
        <div className='news__item__date'>
          2020
        </div>
        <div className='news__item__content'>
          <p>NY/LA is out in the world! Enjoy :)</p>
          <iframe src="https://player.vimeo.com/video/334327158?h=3ec3c60fdd" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div className='news__item'>
        <div className='news__item__date'>
          2019.05.13
        </div>
        <div className='news__item__content'>
          The trailer for&nbsp;
          <a
            href='https://www.imdb.com/title/tt8552748/?ref_=nm_flmg_act_1'
            target='_blank'
            rel='noopener noreferrer'
          >
            NY/LA
          </a>
          &nbsp;is out now!
          <br/><br/>
          I worked on this webseries with Rob Asaro in fall 2018 and had such a great time.
          <br/>
          Can't wait to share the full pilot!
          <br/><br/>
          <iframe src="https://player.vimeo.com/video/335630860?h=e8b8cd3d32" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
)

const Reel = () => (
  <div>
  <iframe src="https://player.vimeo.com/video/734974306?h=f20ae3e0d8" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  <p>
    1. Bull - Taylor @ <a href="https://vimeo.com/734974306#t=0m0s">0:00</a><br />
    2. Eli Stone - Beth @ <a href="https://vimeo.com/734974306#t=0m24s">0:24</a><br />
    3. I Love That For You - Joanna @ <a href="https://vimeo.com/734974306#t=1m6s">1:06</a><br />
    4. Bull - Juror (Cecilia) @ <a href="https://vimeo.com/734974306#t=1m30s">1:30</a><br />
    5. The Last OG - Guidance Counselor @ <a href="https://vimeo.com/734974306#t=0m0s">1:50</a>
  </p>
  </div>
)

const Media = () => {
  // leaving out: 107, 309, 322
  const photoNumbers = [
    367, 198, 5,
    241, 339, 52,
    439, 172, 44,
  ]

  return (
    <div style={{ maxWidth: '1000px' }}>
      {photoNumbers.map((number) => <Image style={{ maxWidth: '50%' }} key={`photo-${number}`} className='header__headshot' src={`img/headshots/helen_${number}-highres.jpg`} />)}
    </div>
  )
}

const Contact = () => (
  <div>
    Email is the best way to reach me!<br/><br/>Hit me up at <a href="mailto:helen.c.hood@gmail.com">helen.c.hood@gmail.com</a> :)
  </div>
)

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header className='App-header'>
          <div className='App-nav__home' >
            <Link to='/'>Helen Hood</Link>
          </div>
          <div className='social'>
            <a
              className='social-icon'
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.imdb.com/name/nm7784976/'
            >
              <img className='social-icon-img' src='img/imdb-icon.svg' alt='imdb' />
            </a>
            <a
              className='social-icon'
              target='_blank'
              rel='noopener noreferrer'
              href='https://resumes.actorsaccess.com/helenhood'
            >
              <img className='social-icon-img' src='img/aa-logo.svg' alt='actors access' />
            </a>
            <a
              className='social-icon'
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/helenchood'
            >
              <img className='social-icon-img' src='img/ig-logo.png' alt='instagram' />
            </a>
          </div>
          <div className='navigation'>
            <div className='navigation__link'>
              <Link to='/about'>About</Link>
            </div>
            <div className='navigation__link'>
              <a
                href='/img/HOOD_HELEN_resume.pdf'
                target='_blank '
                rel='noopener noreferrer'
              >
                Resume
              </a>
            </div>
            <div className='navigation__link'>
              <Link to='/reel'>Reel</Link>
            </div>
            <div className='navigation__link'>
              <Link to='/gallery'>Gallery</Link>
            </div>
            <div className='navigation__link'>
              <Link to='/news'>News</Link>
            </div>
            <div className='navigation__link'>
              <Link to='/contact'>Contact</Link>
            </div>
          </div>
        </header>
        <div className='App-body'>
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/about' element={<About />}/>
            <Route path='/reel' element={<Reel />}/>
            <Route path='/gallery' element={<Media />}/>
            <Route path='/news' element={<News />}/>
            <Route path='/contact' element={<Contact />}/>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
